.stpperform{
    padding: 50px 150px;
    background-image: url(../../../Assets/Image/kk.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.personalDetails{
  color: white;
 margin: 50px 20px;
 box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
padding: 30px 20px;
border-radius: 10px;
background: linear-gradient(rgba(78, 77, 77, 0.8),rgba(0, 0, 0, 0.8));

}
.MuiStepLabel-label{
  color: white !important;
}
.details-form{
margin: 30px 100px;  
display: flex;
justify-content: space-evenly; 
flex-wrap: wrap;

}
.tet,.test{
    width: 250px;
}

.details-forms{
    margin: 30px 0px;  
    display: flex;
    justify-content: space-evenly;  
    flex-wrap: wrap;
    }

    .checkboxform{
        display: grid;
        grid-template-columns: 30% 30%;
        
    }
    .detailsform{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 0px 200px 0px 400px;
        row-gap: 25px;
    }

    .list-item{
        margin-top: 20px;
    }

    .summary{
        display: grid;
        grid-template-columns: 30% 30% 30%;
        gap: 30px;
        padding: 10px 100px;

    }
    
    /* Styles for medium screens (tablets) */

@media only screen and (max-width: 1024px) {
    .stpperform {
      padding: 30px 100px;
    }
    .tet{
        width: 200px;
    }
    .test{
        width: 170px;
    }
  
    .personalDetails {
      margin: 30px 10px;
      padding: 20px 10px;
    }
  
    .details-form {
      margin: 20px 0px;
    }
  
    .details-forms {
      margin: 20px 0px;
    }
  
    .checkboxform {
      grid-template-columns: 50% 40%;
    }
  
    .detailsform {
      padding: 0px 100px 0px 200px;
    }
  
    .list-item {
      margin-top: 15px;
    }
    .summary{
        display: grid;
        grid-template-columns: 1fr;
     margin-left: 100px;

    }
  }

  .css-14lo706 > span {
    font-size: 0.75rem !important;
  }
  .css-v7esy{
    font-size: 0.75rem !important;
  }
  /* Styles for small screens (mobiles) */
  
  @media only screen and (max-width: 768px) {
    .stpperform {
      padding: 20px 50px;
    }
    .tet,.test{
        width: 250px;
    }
    
  
    .personalDetails {
      margin: 20px 5px;
      padding: 15px 5px;
    }
  
    .details-form {
      margin: 15px 20px;
      flex-direction: column;
      align-items: center;
    }
  
    .details-forms {
      margin: 15px 0px;
      flex-direction: column;
      align-items: center;

    }
  
 
  
    .detailsform {
      padding: 0px 50px 0px 100px;
    }
  
    .list-item {
      margin-top: 10px;
    }
    .summary{
        padding: 10px;
    }
  }
  
  /* Styles for extra small screens (small mobiles) */
  
  @media only screen and (max-width: 480px) {
    .stpperform {
      padding: 15px 7px;
    }
    .summary{
        display: grid;
        grid-template-columns: 1fr;
     margin-left: 10px;
     padding: 10px;


    }
  
    .personalDetails {
      margin: 15px 2px;
      padding: 10px 2px;
    }
    .tet,.test{
        width: 220px;
    }
  
    .details-form {
      margin: 10px 15px;
    }
  
    .details-forms {
      margin: 10px 0px;
    }
  
    .checkboxform {
      grid-template-columns: 60% 40%;
    }
  
    .detailsform {
      padding: 0px 30px 0px 20px;
    }
  
    .list-item {
      margin-top: 5px;
    }
  }


    
