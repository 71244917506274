.infromation-header{
    display: flex;
    justify-content: space-between;
    background-color: rgb(9, 165, 186);
    text-align: center;
    align-items: center;
    align-content: center;
    height:50px ;
    padding: 10px;
}
.headerContact{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    margin: 0 10px;  
}

.headerContact >p{
    font-size: 16px;
    cursor: pointer;
    margin: 0 5px;  
    color: white;
}

@media   (max-width: 560px) {
    .infromation-header{
        display: none;
    }
    .headerContact {
        display: flex;
        align-items: flex-end; /* Align items to flex-end */
        text-align: center;
        margin: 5px 0; /* Adjust margin for better spacing */
    }

    .headerContact:first-child {
        align-self: flex-end; /* Ensure the first child is aligned to the flex end */
    }
    
}
/* Common link styles */
.custom_nav-container .navbar-nav .nav-item .nav-link {
    padding: 5px 20px 0px 20px;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    border-radius: 5px;
    text-decoration: none; /* Remove default underline */
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    position: relative; /* Required for the underline */
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

/* Underline styles */
.custom_nav-container .navbar-nav .nav-item .nav-link::after {
    content: '';
    position: absolute;
    background-color: white; /* Color of the underline */
    height: 2px; /* Thickness of the underline */
    margin: 0px 20px;
    width: 70%; /* Full width of the text */
    left: 0;
    bottom: 0px; /* Position slightly below the text */
    transform: scaleX(0); /* Start with no visible width */
    transform-origin: left; /* Start scaling from the left */
    transition: transform 0.3s ease;
}

/* Hover state: expand the underline to match content width */
.custom_nav-container .navbar-nav .nav-item .nav-link:hover::after {
    transform: scaleX(1); /* Expand to full width */
}

.custom_nav-container .navbar-nav .nav-item .nav-link:hover {
    color: #fff; /* Change text color on hover */
}

/* Active link styles */
.custom_nav-container .navbar-nav .nav-item.active .nav-link {
    color: #fff; /* Active link text color */
}

.custom_nav-container .navbar-nav .nav-item.active .nav-link::after {
    transform: scaleX(1); /* Full width for active links */
}

  
 
  
  
  
 
  
  
