.product-show{
    padding: 100px 200px;
    display: grid;
    grid-template-columns: 23% 23% 23% 23%;
    gap:30px
}

.detail-boxs{
padding: 15px 0px;
text-align: center;
text-transform: uppercase;
font-size: 25px;
}


.box{
  transition: 500ms;
  background:linear-gradient(rgba(0, 0, 0, 0.4));

}
.box:hover{
  transform: scale(1.1);
}

.box:hover .img-box{
  opacity: 0.7;
  background:linear-gradient(rgba(0, 0, 0, 0.4));
}

.click-me-btn {
  opacity: 0;
  visibility: hidden;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  transition: opacity 0.3s ease-in-out,visibility 0.3s ease-in-out;
 
}

.box:hover .click-me-btn {
  opacity: 1 ;
  visibility: visible;
  color: white;
  border: 3px solid #09a5ba;
}


@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .product-show {
      grid-template-columns: repeat(4, 1fr);
      padding: 50px 50px;
      gap: 30px 40px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 998px) {
  .product-show {
      grid-template-columns: repeat(3, 1fr);
      padding: 50px 50px;
  }
}

/* For small screens (max-width: 768px) */
@media only screen and (max-width: 768px) {
    .product-show {
      grid-template-columns: repeat(2, 1fr);
      padding: 50px 50px;
    }
  }
  
  /* For extra small screens (max-width: 480px) */
  @media only screen and (max-width: 480px) {
    .product-show {
      grid-template-columns: 1fr;
      padding: 25px 25px;
    }
    .detail-boxs {
      font-size: 18px;
    }
  }