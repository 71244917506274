#details{
    background-color: #62d2a2;
    box-shadow: 3px 3px 10px gray ;
     color:white;
    padding:30px;
    border-radius: 10px;
    margin-top: 30px;
}


.homegrid{
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 30px;
    padding: 0px 200px;
}
#detail{
    background-color: white;
    box-shadow:  inset 0 0 10px gray ;
    color:#62d2a2;
   padding:10px;
   border-radius: 10px;
  
}
.detail > h2 {
    display: flex;
    align-items: center;
}
.ttm-row{
margin-top: 90px;
background-image: linear-gradient( rgba(98, 210, 162,0.2) , rgba(98, 210, 162,0.8));
padding: 40px 0px;
}
.section-title .title-header {
    padding-bottom: 11px;
}
.section-title h5 {
    font-family: "Barlow Condensed", Arial, Helvetica, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 3px;
    color: #7f8284;
    border-bottom: 2px solid;
    display: inline-block;
    border-color: #ffaa01;
    margin-bottom: 20px;
}
.title-header h2{
    margin-bottom: 20px;
}
.featured-icon-box.style5 {
    padding: 0 15px;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.08));
    padding: 30px 30px 30px;
    position: relative;
    text-align: left;
    color: #62d2a2;
    background-color: white;
}

.card{
    background:#62d2a2;
    width: auto;
 height: 350px;
    border-radius: 40px 0 40px 0;
    margin-top: 10px;
    justify-content: space-evenly;
}
.featured-title h3 {
    font-size: 22px;
    line-height: 25px;
    margin-bottom: 12px;
    font-weight: 600;
    text-transform: capitalize;
}
.featured-icon-box.icon-align-top-content .featured-content {
    padding-top: 15px;
}

.products{
    background-color: white;
    color: black;
}

.boxs{
    background-color: #62d2a2;
}

#detail > span{
    font-size: 25px;
    margin-left: 10px;
}

.icon-home{
    font-size: 55px;
    transition: font-size 0.3s ease;
}


@media (max-width: 1024px) {
   
}
/* Responsive adjustments */
@media (max-width: 1200px) {
    .icon-home {
        font-size: 50px; /* Adjust size for larger tablets and smaller desktops */
    }
}

@media (max-width: 992px) {
    .icon-home {
        font-size: 45px; /* Adjust size for tablets */
    }
}

@media (max-width: 768px) {
    .icon-home {
        font-size: 40px; /* Adjust size for smaller tablets and large phones */
    }
    .card{
        background:#62d2a2;
        width: auto;
     height: 350px !important;
        border-radius: 40px 0 40px 0;
        margin-top: 10px;
        justify-content: space-evenly;
    }
}

@media (max-width: 576px) {
    .icon-home {
        font-size: 35px; /* Adjust size for mobile phones */
    }
}

@media (max-width: 1200px) {
    .homegrid {
        grid-template-columns: 31% 31% 31%;
        gap: 25px;
        padding: 0 50px; /* Reduce padding */
    }
}

/* Adjust grid layout for tablets and small desktops */
@media (max-width: 992px) {
    .homegrid {
            grid-template-columns: 48% 47%;
            gap: 25px;
            padding: 0 40px;
           

        
    }
    .homegrid div:nth-child(3) {
        grid-column: 1 / -1;
        justify-self: center;
        width: 48%;
    }
}

/* Adjust grid layout for small tablets and large phones */
@media (max-width: 768px) {
    .homegrid {
       grid-template-columns: 48% 47%;
        gap: 25px;
        padding: 0 40px; 
       
    }

    .homegrid div:nth-child(3) {
        grid-column: 1 / -1;
        justify-self: center;
        width: 48%;
    }

  
}

/* Adjust for mobile phones */
@media (max-width: 576px) {
    .homegrid {
        grid-template-columns: 1fr; /* 1 column */
        gap: 10px;
        padding: 10px; /* No padding */
    }   
    .homegrid div:nth-child(3) {
        grid-column: unset; /* remove grid-column property */
        width: 100%; /* make the div full-width */
    }
}


.homeImage{
    height: 718px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}


@media (max-width: 1200px) {
    .homeImage {
        height: 600px;
    }
}

/* For screens smaller than 992px (tablets) */
@media (max-width: 992px) {
    .homeImage {
        height: 500px;
    }
}

p{
   font-size: 18px !important;
  }

  a{
    font-size: 18px !important;
  }
  span{
    font-size: 18px !important;
  }


  
  /* For laptops */
  @media (max-width: 1024px) {
    p ,a,span{
      font-size: 16px  !important; /* Font size for laptop */
    }
  }
  
  /* For tablets */
  @media (max-width: 768px) {
    p ,a,span{
      font-size: 16px !important; /* Font size for tablets */
    }
  }
  
  /* For mobile phones */
  @media (max-width: 480px) {
    p,a,span {
      font-size: 15px !important; /* Font size for mobile phones */
    }
    .featured-content{
      
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
  }
  
  .ouroutcomes {
    padding: 20px; /* Overall padding for the section */
}

.featuredbox-number {
    display: grid; /* Enable grid layout */
    grid-template-columns: repeat(4, 1fr); /* Default to one column */
    gap: 20px; /* Space between grid items */
}

/* For large screens (1024px to 1440px) */
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    .featuredbox-number {
        grid-template-columns: repeat(2, 1fr); /* Two columns layout */
        padding: 50px; /* Adjust padding */
    }
}

/* For medium screens (769px to 1024px) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .featuredbox-number {
        grid-template-columns: repeat(2, 1fr); /* Two columns layout */
        padding: 50px; /* Adjust padding */
    }
}

/* For small screens (max-width: 768px) */
@media only screen and (max-width: 768px) {
    .featuredbox-number {
        grid-template-columns: repeat(2, 1fr); /* Two columns layout */
        padding: 50px; /* Adjust padding */
    }
}

/* For extra small screens (max-width: 480px) */
@media only screen and (max-width: 480px) {
    .featuredbox-number {
        grid-template-columns: 1fr; /* Single column layout */
        padding: 25px; /* Adjust padding */
    }
}

/* Featured Icon Box Styles */
.featured-icon-box {
    background-color: white; /* Ensure consistent background */
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.08));
    border-radius: 40px 0 40px 0; /* Rounded corners */
    padding: 30px; /* Padding for inner content */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
    display: flex;
    flex-direction: column; /* Stack content vertically */
    justify-content: space-between; /* Distribute space evenly */
    align-items: center; /* Center content horizontally */
    height: 100%; /* Allow the box to take full height of the grid item */
}

.ouroutcomes-item {
    display: flex; /* Use flexbox for item */
    flex-direction: column; /* Stack items vertically */
    justify-content: stretch; /* Stretch items to fill the height */
}

