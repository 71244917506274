.cards {
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding:0px 15px;
  }
  
 .socialContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
 
}

.socialContainer:hover {
  background-color: #444;
}

.socialContainer .fa, .socialContainer .md, .socialContainer .fab {
  font-size: 20px;
  color: #fff;
  margin: 0 auto;
}

.containerOne:hover, .containerFour:hover, .containerFive:hover {
  background-color: #333;
  transition-duration: .3s;
}

.containerOne, .containerFour, .containerFive {
  background-color: #62d2a2;
}

.containerOne .fa, .containerFour .fa, .containerFive .md {
  margin-top: 10px;
}

.containerOne:hover .fa, .containerFour:hover .fa, .containerFive:hover .md {
  margin-top: 0px;
}



@media only screen and (max-width: 768px) {
  .footerpage{
    margin-left: 0px;
  }
}


.gototop-center {
  text-align: center; /* center the button horizontally */
  margin: 20px auto; /* add some margin to make it look better */
}

.gototop-center > p {
  display: inline-flex; /* make the paragraph an inline flex container */
  align-items: center; /* center the icon and text vertically */
  justify-content: center; /* center the icon and text horizontally */
}

.gototop-center  > span {
  font-size: 25px; /* set the font size for the icon and text */
}

/* medium screens (tablets) */
@media (max-width: 768px) {
  .gototop-center span {
    font-size: 20px;
  }
}

/* small screens (mobiles) */
@media (max-width: 425px) {
  .gototop-center span {
    font-size: 18px;
  }
}

.f-p {
  padding-left: 45px; /* Default for desktop */
}

/* For laptops */
@media (max-width: 1024px) {
  .f-p {
    padding-left: 20px; /* Padding for laptop */
  }
}

/* For tablets */
@media (max-width: 768px) {
  .f-p {
    padding: 15px; /* No padding for tablet */
  }
}
                