.producuts-show{
    display: flex;
    flex-direction: row;
    gap:50px;
align-items: center;
padding: 0px 50px;
}

.products{
    padding: 50px 200px 30px 200px;
}
.product-name{
    margin-top: 20px;
}
.product-name >p{
    text-align: center;
    font-size: 30px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.products-images>img{
    border-radius: 5px;
    height: 500px;
    width: 400px;
   
}

.products-content{
    background-image: linear-gradient( rgba(98, 210, 162,0.2) , rgba(98, 210, 162,0.8));
    padding: 50px;
    border-radius: 5px;
}
.products-content>p{
    font-size: 20px;
}

.sub-image{
    display: grid;
    grid-template-columns: 23% 23% 23% 23%;
    gap:20px;
    padding:50px 200px 30px 200px
}

.products-description{
    font-size: 20px;
    margin:30px 0px;
    text-align: center;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4)),url(../../../Assets/Image/1.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 20px;
    border-radius: 5px;
    color: white ;


}

.image-des{
    display: flex;
    align-items:flex-end ;
    justify-content: space-between;
    padding: 0px 150px;
}
.image-des>img{
    width:100px ;
height: 100px;
}


/* Media query for laptop (max-width: 1200px) */
@media (max-width: 1200px) {
    .products {
        padding: 30px 100px;
    }

    .sub-image {
        grid-template-columns: 31% 31% 31%;
        gap: 15px;
        padding:  30px 100px;
    }
    .products-images>img{
        height:300px;
        width:300px;
    }
    .producuts-show{
  
    padding: 0px;
    }
    .image-des{
        
        padding: 0px 100px;
    }
}

/* Media query for tablet (max-width: 768px) */
@media (max-width: 768px) {
    .producuts-show {
        flex-direction: column;
        gap: 20px;
    }

    .products {
        padding: 20px 50px;
    }

    .product-name > p {
        font-size: 24px;
    }

    .products-content {
        padding: 30px;
    }

    .sub-image {
        grid-template-columns: 45% 45%;
        gap: 20px 40px;
        padding: 20px 50px;
    }
  
}

/* Media query for phone (max-width: 480px) */
@media (max-width: 480px) {
    .producuts-show {
        flex-direction: column;
        gap: 10px;
    }

    .products {
        padding: 10px 20px;
    }

    .product-name > p {
        font-size: 18px;
    }

    .products-content {
        padding: 20px;
    }

    .sub-image {
        grid-template-columns: 100%;
        gap: 10px;
        padding:10px 20px
    }
    .products-images>img{
        height:200px;
        width:200px;
    }
    .image-des{
        
        padding: 0px 30px;
    }
}