.cardss{
    background: #62d2a2;
    width: auto;
    border-radius: 40px 0 40px 0;
    margin-top: 10px;

}

.img-fluid{
    height: 578px;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .img-fluid {
        height: auto; /* Ensure it adjusts for smaller screens */
    }
}
